import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
const ProductsCard = ({ img, name, role, id ,desc }) => {
  
  
  return (
    <Card style={{ width: "14rem" }} >
      <Card.Img className="card-img" variant="top" src={img} />
      <Card.Body className="d-flex flex-column justify-content-between">
        <Card.Title>{name}</Card.Title>
        <Card.Text>{desc===null?"...":desc}</Card.Text>
        <div className="w-100 d-flex align-items-end justify-content-end">
          <Link
            className="explore-btn"
            to={role === "subCategory" ? `/products/${id}` : `/product/${id}`}
          >
            Explore
            <FontAwesomeIcon className="ms-2" icon={faArrowRight} />
          </Link>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProductsCard;
