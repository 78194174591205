import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import logo from '../images/GEATIX LOGO  1.png'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons'
import links from '../images/links.png'
import footerImg from "../images/footer.png"
import jsonData from '../constants.json'

let socials = '';
let facebook = '';
let instagram = '';
let tiktok = '';

const getConstants = () => {
    let social = jsonData.socials[0];
    let facebooks = social.facebook;
    let instagrams = social.instagram;
    let tiktoks = social.tiktok;

    facebook = facebooks.map(function(value) {
        return <a key={value} href={value} target="_blank"><FontAwesomeIcon size='xl' icon={faFacebook} /></a>
        ;
      });
    instagram = instagrams.map(function(value) {
        return <a key={value} href={value} target="_blank"><FontAwesomeIcon size='xl' icon={faInstagram} /></a>
        ;
      });
    tiktok = tiktoks.map(function(value) {
        return <a key={value} href={value} target="_blank"><FontAwesomeIcon size='xl' icon={faTiktok} /></a>
        ;
      });
}
getConstants()
const Footer = () => {
    return (
        <footer className='mt-5 position-relative' >
            <img src={links} className=' position-absolute bottom-0 start-0' style={{width:"34%"}}/>
            <Container>
                <Row className='pt-4'>
                    <Col xs={12} md={4}>
                        <Link to={'/'}><img className='img-fluid w-50' src={logo} alt='logo' /></Link>
                    </Col>
                    <Col xs={12} md={4}>
                        <h4 className='text-light '>Links</h4>
                        <ul className=' p-0 d-flex flex-column  gap-2  mt-3 '>
                            <li><Link to={"/"} className='text-light p-0'>Home</Link></li>
                            <li><Link to={"/about"} className='text-light p-0'>About Us</Link></li>
                            <li><Link to={"/contact"} className='text-light p-0'>Contact Us</Link></li>
                        </ul>
                    </Col>
                    <Col xs={12} md={4}>
                        <h4 className='text-light  mt-xs-3'>Follow Us</h4>
                        <div className='d-flex align-items-center justify-content-stert gap-4 mt-3 social-links'>
                            {facebook}
                            {instagram}
                            {tiktok}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className='d-flex align-items-center justify-content-center mt-5 pb-2'>
                            <p className='text-light m-0'>&copy; Copyright 2024 Geatix</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer
