import React from 'react'
import CardPlaceholder from '../CardPlaceholder'
import { BaseURL } from '../../Api/Api'
import CategoryCard from './CategoryCard'
import logo from "../../images/logo.png"
const CategoryCardsContainer = ({ data, role, loading }) => {
  const data1 = data || []

  const dataShow = data1.map((el, i) => {
    return (
      <CategoryCard role={role} key={i} img={el.image===null?logo:`${BaseURL}${role === "search" ? el.thumbnail : el.image}`} desc={role === "subCategory" ? el.subtitle : el.name} id={el.id} />
    )
  })

  return (
    <div className='cards-container'>
      <CardPlaceholder loading={loading}>{dataShow}</CardPlaceholder>
    </div>
  )
}

export default CategoryCardsContainer
