import React from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
const CategoryCard = ({ img, desc, role, id }) => {
  const sliceText = desc.slice(0, 10);
  return (
    <Link
      to={role === "subCategory" ? `/products/${id}` : `/product/${id}`}
      className="ani-fade  category-card"
    >
      <img className="category-card-img " variant="top" src={img} />
      <div className="px-1">
        <p title={desc}>{desc}</p>
      </div>
    </Link>
  );
};

export default CategoryCard;
