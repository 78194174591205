//import { facall } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import jsonData from '../constants.json'
let whatsNumber = '';
const getConstants = () => {
  whatsNumber = jsonData.whatsappNumber;
}
getConstants()
const SupportCircle = () => {
  const location = useLocation()
  const [pathname, setPathName] = useState(location.pathname)

  useEffect(() => {
    setPathName(location.pathname)
  }, [location.pathname])

  return (
    <Link to={'https://wa.me/'+whatsNumber} >
      <div className='sup-circle fade-circle shadow ' style={{ transform: "scale(1)" }}
      >
        <i className="text-light m-0 fa fa-whatsapp fa-3x" aria-hidden="true"></i>
      </div>
    </Link>
  )
}

export default SupportCircle
