import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import { BaseURL } from '../../Api/Api'
import { useSelector } from 'react-redux'
import Loading from '../Loading'
import ComponentsLoader from "../ComponentsLoader"
const FirstSection = ({ image,categoryName }) => {
    const {loading }=useSelector(state=>state.CategorySlice)
    return (
        <section className='mb-5 '>
            <Row style={{minHeight:"40vh"}} >
                <Col lg={5} xs={12} className=' center  '>
                    <ComponentsLoader loading={loading}><div className=' category-img'><img className='rounded' src={`${BaseURL}${image}`} alt="battery" /></div></ComponentsLoader>
                </Col>
                <Col lg={7} xs={12} className='center category-name'>
                    <h1>{categoryName}</h1>
                </Col>
            </Row>
        </section>
    )
}

export default FirstSection
