import React from "react";
import geatix from "../../images/geatix-home-section.png";
const HomeGeatix = () => {
  return (
    <div>
      <section
        className=" mt-5"
        style={{ position: "relative", overflow: "hidden" }}
      >
        <img
          data-aos="zoom-up"
          data-aos-duration="2000"
          src={geatix}
          alt="geatix"
          className="w-100"
        />
      </section>
    </div>
  );
};

export default HomeGeatix;
