import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import icon1 from '../../images/icon1.png'
import icon2 from '../../images/icon2.png'
import icon3 from '../../images/icon3.png'
const HomeAboutUs = () => {
    return (
        <section className=' mt-5' style={{ position: "relative", overflow: "hidden" }}>
            <Container>
                <h2 className='main-color text-center fw-bold'>Our <span className='primery-color'>Core Values</span></h2>
                <Row className=''>
                    <Col
                    sm={12}
                    md={4}
                    className='d-flex flex-column align-items-center justify-content-start text-center gap-3 px-5 '>
                    <div data-aos="fade-right" className='about-circle shadow'>
                            <img loading='lazy' src={icon1} alt="1" />
                        </div>
                        <h5 
                        className='primery-color fw-bold mt-4'
                        data-aos="fade-up" data-aos-duration="1500" 
                        >Dedication to Commitment</h5>
                        <p data-aos="fade-up" data-aos-duration="1500"></p>
                    </Col>
                    <Col
                        sm={12}
                        md={4}
                        className='d-flex flex-column align-items-center justify-content-start text-center gap-3 px-5'>
                        <div data-aos="fade-up" className='about-circle shadow'>
                            <img loading='lazy' src={icon2} alt="1" />
                        </div>
                        <h5 data-aos="fade-up" data-aos-duration="1500" className='primery-color fw-bold mt-4' >Quality Excellence</h5>
                        <p data-aos="fade-up" data-aos-duration="1500"></p>
                    </Col>
                    <Col
                        
                        sm={12}
                        md={4}
                        className='d-flex flex-column align-items-center justify-content-start text-center gap-3 px-5'>
                        <div data-aos="fade-left" className='about-circle shadow'>
                            <img loading='lazy' src={icon3} alt="1" />
                        </div>
                        <h5  data-aos="fade-up" data-aos-duration="1500" className='primery-color fw-bold mt-4' >Integrity and Transparency</h5>
                        <p  data-aos="fade-up" data-aos-duration="1500"></p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default HomeAboutUs
