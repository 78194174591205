import React, { Fragment } from 'react'
import logo from '../images/logo.png'
const Loading = ({ loading, children }) => {
    return (
        <Fragment>
            {loading ? (
                <div className='d-flex align-items-center justify-content-center position-relative' style={{ minHeight: "inherit" }}>
                    <img className='loading-img' src={logo} alt='loading...'/>
                </div>
            ) : (children)}
        </Fragment>
    )
}
export default Loading
