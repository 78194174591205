import React, { useEffect } from 'react'
import Container from 'react-bootstrap/esm/Container'
import FirstSection from '../components/Category Page/FirstSection'
import CategoryCardsContainer from '../components/Category Page/CategoryCardsContainer'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCategory } from '../state/CategorySlice'
import Err from '../components/Err'

const CategoryPage = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { category, loading, error } = useSelector(state => state.CategorySlice)

  useEffect(() => {
    if (id != category.category_id) {
      dispatch(getCategory(id))
    }
  }, [pathname])
  

  return (
    <Container style={{ marginTop: "58px" }}>
      <Err error={error}>
        <FirstSection categoryName={category.category_name} image={category.category_image} />
        <CategoryCardsContainer loading={loading} role={"subCategory"} data={category.subcategories} />
      </Err>
    </Container>

  )
}

export default CategoryPage
