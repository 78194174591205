import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseApiURL } from "../Api/Api";

const initialState = {
    products: [],
    error: null,
    loading: false,
    success: true
}

export const getProducts = createAsyncThunk("products/getProducts", async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    console.log(data)
    try {
        const res = await axios.get(`${BaseApiURL}/psubcategory/${data.id}/${data.page}`);
        return res.data
    } catch (error) {
        return rejectWithValue(error)
    }
})

const ProductsSlice = createSlice({
    name: "products",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getProducts.pending, (state, action) => {
                state.loading = true;
                state.error = null
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.products=state.products.concat(action.payload.data)
                state.error = null
                state.success=action.payload.success
            })
            .addCase(getProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload
            })
    }

})

export default ProductsSlice.reducer