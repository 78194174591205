import React, { Fragment } from "react";

const ComponentsLoader = ({ loading, children }) => {
  return (
    <Fragment>
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center position-relative"
          style={{ minHeight: "inherit" }}
        >
          <div className="loader">
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
            <div className="bar4"></div>
            <div className="bar5"></div>
            <div className="bar6"></div>
            <div className="bar7"></div>
            <div className="bar8"></div>
            <div className="bar9"></div>
            <div className="bar10"></div>
            <div className="bar11"></div>
            <div className="bar12"></div>
          </div>
        </div>
      ) : (
        children
      )}
    </Fragment>
  );
};

export default ComponentsLoader;
