import React from 'react'
import CardPlaceholder from '../CardPlaceholder'
import { BaseURL } from '../../Api/Api'
import ProductsCard from "./ProductsCard"
import logo from "../../images/logo.png"

const ProductsCardsContainer = ({ data, role, loading }) => {
  const data1 = data || []
  const dataShow = data1.map((el, i) => {
    //console.log(el.image)
    return (
      <ProductsCard role={role} key={i} img={el.image === null||el.thumbnail===null ? logo :`${BaseURL}${role === "search" ? el.thumbnail : el.image}`} name={el.name} desc=
      {el.description} id={el.id} />
    )
  })
 

  return (
    <div className='cards-container '>
      {dataShow}
      {loading&&<CardPlaceholder loading={true}/>}
    </div>
  )
}

export default ProductsCardsContainer
